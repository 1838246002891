<template>
  <div class="radimix-login uk-background-primary">
    <div class="uk-card uk-card-body uk-card-default uk-width-1-2 uk-align-center">
      <h3 class="uk-card-title">Administraator</h3>

      <vk-spinner v-if="LoginSystem.login_state == 1"></vk-spinner>

      <div v-if='LoginSystem.login_state == 3' class="uk-alert-success" uk-alert>
        <p>{{ LoginSystem.login_message }}</p>
    </div>

    <div v-if='LoginSystem.login_state == 2' class="uk-alert-warning" uk-alert>
        <p>{{ LoginSystem.login_message }}</p>
    </div>

      <div class="uk-margin">
          <input class="uk-input uk-form-width-medium" v-model.lazy="kasutajanimi" type="text" placeholder="Kasutajanimi">
      </div>

      <div class="uk-margin">
          <input class="uk-input uk-form-width-medium" v-model.lazy="parool" type="password" placeholder="Parool">
      </div>

      <vk-button @click="login();" type="primary">Logi sisse</vk-button>
    </div>
  </div>
</template>

<script>
  import { getModule } from 'vuex-module-decorators';
  import LoginModule from '@/store/modules/radimix_login'

  import router from '@/router'

  export default {

    data() {
      return {
        kasutajanimi: '',
        parool: ''
      }
    },

    methods: {
      async login() {
        await getModule(LoginModule, this.$store).login({kasutajanimi: this.kasutajanimi, parool: this.parool})
        router.push("/administraator")
      }
    },

    computed: {
      LoginSystem: function() {
        return getModule(LoginModule, this.$store)
      }
    }
    
  };
</script>

